import AffinityPartnersIcon from '@/components/icons/AffinityPartners.vue';
import AnalyticsDashboardsIcon from '@/components/icons/AnalyticsDashboardsIcon.vue';
import ConfigurationIcon from '@/components/icons/ConfigurationIcon.vue';
import ConnectionsIcon from '@/components/icons/ConnectionsIcon.vue';
import CreateIcon from '@/components/icons/CreateIcon.vue';
import CustomerEngagementIcon from '@/components/icons/CustomerEngagementIcon.vue';
import HomeIcon from '@/components/icons/HomeIcon.vue';
import TaxonomyIcon from '@/components/icons/TaxonomyIcon.vue';
import UsersIcon from '@/components/icons/UsersIcon.vue';
import AssetsIcon from '@/components/icons/AssetsIcon.vue';

export const items = [
    {
      disabled: false,
      text: 'Home',
      path: '/home',
    },
    {
      disabled: false,
      text: 'Create',
      path: '/create-tenant',
    },
    {
      text: 'Connections',
      path: '/connections',
    },
    {
      text: 'Taxonomy',
      path: '/taxonomy',
    },
    {
      text: 'Configurations',
      path: '/legacy-configurations',
    },
    {
      text: 'Users',
      path: '/users',
    },
    {
      text: 'Assets',
      path: '/assets',
    },
    // {
    //   text: 'Analytics Dashboards',
    //   path: '/quick-sight',
    // },
    {
      disabled: false,
      text: 'Customer Engagement',
      path: '/customer-engagement',
    },
  ];

  export const iconComponents: any = {
    'Home': HomeIcon,
    'Affinity Partners': AffinityPartnersIcon,
    Configurations: ConfigurationIcon,
    Connections: ConnectionsIcon,
    'Create': CreateIcon,
    'Analytics Dashboards': AnalyticsDashboardsIcon,
    'Taxonomy': TaxonomyIcon,
    Users: UsersIcon,
    'Customer Engagement': CustomerEngagementIcon,
    'Assets': AssetsIcon
  };