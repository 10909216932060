<template>
  <div :class="$style['sidebar-container']">
    <ul :class="$style['menu']">
      <template
        v-for="item in items"
        :key="item.text"
      >
        <li :class="$style['menu-item']">
          <component
            :is="item.disabled ? 'span' : 'router-link'"
            :to="item.path"
            :class="[
              $style.route,
              isActive(item.path) ? $style.active : '',

              item.disabled !== undefined
                ? item.disabled
                : !tenantStore.getSelectedTenantId
                  ? $style.disabled
                  : '',
            ]"
          >
            <component
              :is="iconComponents[item.text]"
              :class="$style['menu-icon']"
            />
            <div :class="$style['menu-text']">
              {{ item.text }}
            </div>
          </component>
        </li>
      </template>
    </ul>
    <UptakeEssentialsConverter :class="$style.converter" />
  </div>
</template>

<script lang="ts" setup>
import useTenantStore from '@/store/tenant';
import { items, iconComponents } from '@/constants/sideNav';
import { useRoute } from 'vue-router';
import UptakeEssentialsConverter from "@/components/uptakeEssentials/UptakeEssentialsConverter.vue"; 

const route = useRoute();

const isActive = (path: string) => {
  return route.path === path || (route.path === '/' && path === '/home');
};
const tenantStore = useTenantStore();
</script>

<style module lang="scss" scoped>
.sidebar-container {
  display: flex;
  flex-direction: column;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(12px);
  background: rgba(20, 20, 20, 0.85); 
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

li {
  list-style: none;
  margin: 20px 7px !important;
}

.menu {
  flex: 1;
  list-style: none;
}

.menu-item {
  width: calc(100% - 15px);
  
}

ul.menu,
ul {
  margin: 0 !important;
  padding: 0 !important;
}

.route {
  padding: 8px;
  display: flex;
  align-items: center;
  color: white;
  transition: all 0.3s ease-in-out;

}

.route:hover .menu-icon,
.active .menu-icon {
  color: white;
}



.menu-icon {
  height: 24px !important;
  width: 24px !important;
  margin-right: 10px;
}

.menu-text {
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  transition: color 0.2s ease-in-out;
}

.disabled {
  color: #999 !important;
  cursor: not-allowed !important;
  opacity: 0.4 !important;
  pointer-events: none;

}

.active {
  background-color: #165ffb;
  border-radius: 8px;
}



.route:hover {
  background: rgba(255, 255, 255, 0.12);
  transform: scale(1.02);
    border-radius: 8px;

}

.converter {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: auto; 
  padding: 12px;
}
</style>