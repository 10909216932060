<template>
  <svg
    id="icon_configuration"
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="#fff"
  >
    <path
      id="Icon_awesome-cog"
      data-name="Icon awesome-cog"
      d="M26.979,17.314l-2.333-1.339a10.425,10.425,0,0,0,0-3.821l2.333-1.339a.656.656,0,0,0,.3-.762,13.539,13.539,0,0,0-3-5.15.662.662,0,0,0-.81-.125L21.142,6.116a10.349,10.349,0,0,0-3.329-1.911V1.533A.653.653,0,0,0,17.3.9a13.845,13.845,0,0,0-5.98,0,.653.653,0,0,0-.515.637V4.211A10.676,10.676,0,0,0,7.474,6.122L5.147,4.783a.653.653,0,0,0-.81.125,13.458,13.458,0,0,0-3,5.15.649.649,0,0,0,.3.762l2.333,1.339a10.425,10.425,0,0,0,0,3.821L1.643,17.319a.656.656,0,0,0-.3.762,13.539,13.539,0,0,0,3,5.15.662.662,0,0,0,.81.125L7.48,22.017a10.349,10.349,0,0,0,3.329,1.911v2.678a.653.653,0,0,0,.515.637,13.845,13.845,0,0,0,5.98,0,.653.653,0,0,0,.515-.637V23.928a10.676,10.676,0,0,0,3.329-1.911l2.333,1.339a.653.653,0,0,0,.81-.125,13.458,13.458,0,0,0,3-5.15.671.671,0,0,0-.307-.768ZM14.308,18.419a4.355,4.355,0,1,1,4.381-4.355A4.374,4.374,0,0,1,14.308,18.419Z"
      transform="translate(-1.311 -0.569)"
    />
  </svg>
</template>