<template>
  <div :class="$style['header']">
    <div :class="[$style['header-item'],$style['header-left']]">
      <img
        alt="uptake logo"
        style="width: 178px"
        src="../../assets/img/Uptake.png"
      ><b>{{ t('shared.management') }}</b>
    </div>
    <div :class="[$style['header-item'],$style['header-center']]">
      <TenantSelection
        v-if="authStore.isAuthenticated"
        :class="$style['dsselect']"
      />
    </div>
    <div :class="[$style['header-item'],$style['header-right']]">
      <DSButton
        label="Logout"
        size="medium"
        @keyup="logout"
        @click="logout"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import TenantSelection from '@/components/tenant/TenantSelection.vue';
import useAuthStore from '@/store/auth';
import { keycloak } from '@/api/auth';
import { useI18n } from 'vue-i18n';

const authStore = useAuthStore();
const { t } = useI18n();

function logout() {
  keycloak.logout();
}

</script>

<style lang="scss" module scoped>

.selectedTenant {
  display:inline-block;
  margin-right: 20px;
  color: grey;
  font-size: 18px;
  font-weight: 600;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px !important;
  padding: 8px 0 0 10px;
  border-bottom: 3px solid #45586e;
}

.dsselect {
  width: 100%;
  padding-bottom: 10px;
  padding-right: 20px;
  display:inline-block;
}

.header-item {
  flex: 1;
  align-items: center;
}

.header-left {
  flex: 0 1 200px;
}

.header-center {
  flex: 1;
  justify-content: center;
  padding: 0 2px;
}

.header-right {
  flex: 0 1 95px;
  justify-content: flex-end;

  button {
    margin-bottom:8px;

  }
}
</style>