<template>
  <div :class="$style.essentials">
    <div :class="$style['essentials-title']">
      Uptake Essentials Converter
    </div>

    <div :class="$style.label">
      Company GUID:
    </div>
    <DSInput
      v-model.trim="companyGuid"
      :class="$style.input"
      autofocus
    />
    <div :class="$style.label">
      Database Name:
    </div>
    <DSInput
      v-model.trim="database"
      :class="$style.input"
    />

    <div :class="$style.buttonContainer">
      <DSButton
        label="Convert"
        :class="$style.button"
        :disabled="isButtonDisabled"
        @click="convertGuidToTenantId"
      />
      <DSButton
        label="Clear"
        variant="secondary"
        :class="$style.clearButton"
        :disabled="isInputsEmpty"
        @click="clearInputs"
      />
    </div>

    <div
      v-if="tenantId"
      :class="$style.subtitle"
    >
      Generated Tenant ID: <br>
      <a
        href="#"
        @click.prevent="navigateToTenant(tenantId)"
      >{{ tenantId }}</a>
    </div>

    <div
      v-else
      :class="$style.hint"
    >
      Press Convert to generate the Tenant ID
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import useTenantStore from '@/store/tenant';

const tenantStore = useTenantStore();

const companyGuid = ref('');
const database = ref('');
const tenantId = ref('');

const isButtonDisabled = computed(() => !companyGuid.value.trim() || !database.value.trim());
const isInputsEmpty = computed(() => !companyGuid.value && !database.value);

watch([companyGuid, database], ([newCompanyGuid, newDatabase]) => {
  if (!newCompanyGuid || !newDatabase) {
    tenantId.value = '';
  }
});

const clearInputs = () => {
  companyGuid.value = '';
  database.value = '';
  tenantId.value = '';
};

const convertGuidToTenantId = async () => {
  if (isButtonDisabled.value) return;
  tenantId.value = await generateBase36TenantId(`${companyGuid.value}${database.value}`);
};

const navigateToTenant = async (selectedTenantId) => {
  const selectedTenant = tenantStore.tenants.find(
    (tenant) => tenant?.tenantId === selectedTenantId
  );
  if (!selectedTenant) {
    console.warn('Tenant not found:', selectedTenantId);
    return;
  }
  tenantStore.setSelectedTenant(selectedTenant);
  tenantStore.setSelectedTenant(selectedTenant);
};

const generateBase36TenantId = async (input) => {
  const fullHash = await hashSHA256(input);
  let base36Hash = BigInt('0x' + fullHash)
    .toString(36)
    .substring(0, 8);
  if (/^[0-9]/.test(base36Hash)) base36Hash = 'a' + base36Hash.slice(1);
  return `uegt${base36Hash}`;
};

const hashSHA256 = async (str) => {
  const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str));
  return Array.from(new Uint8Array(hashBuffer), (b) => b.toString(16).padStart(2, '0')).join('');
};
</script>

<style lang="scss" module>
.essentials {
  background: white;
  max-width: 400px;
  margin: 6px;
  margin-bottom: 7px;
  border: 1px solid #ccc;
  text-align: center;
  color: black;
  line-height: 1.2;

}

.input {
  margin-bottom: 10px;
}

.essentials-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
  line-height: 1.2;
}

.buttonContainer {
  padding-top:4px;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button:hover:not(:disabled) {
  background-color: #0056b3;
}

.hint {
  color: gray;
  font-size: 14px;
}

</style>
