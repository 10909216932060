<template>
  <DSSelect
    v-model="selectedTenantId"
    aria-label="Select Tenant"
    aria-labelledby="SelectTenantLabel"
    :items="typeItems"
    placeholder="Select Tenant"
    datacy="Tenant Selection"
    @change="onChangeSelect"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import useTenantStore from '@/store/tenant';
import useConnectorConfigStore from '@/store/connectorConfigs';
import useUsersStore from '@/store/users';
import useTaxonomyStore from '@/store/taxonomy';
import useConfigurationStore from '@/store/configuration';
import { useRouter, useRoute } from 'vue-router';

const tenantStore = useTenantStore();
const connectorConfigStore = useConnectorConfigStore();
const taxonomyStore = useTaxonomyStore();
const configurationStore = useConfigurationStore();
const usersStore = useUsersStore();
const router = useRouter();
const route = useRoute();

const typeItems = ref<{ label: string; value: string }[]>([]);

const selectedTenantId = computed(() => tenantStore.getSelectedTenantId ?? "");


const onChangeSelect = async (event: any) => {
  const selectedTenantId = event.target.value;  

  const selectedTenant = tenantStore.tenants.find(
    (tenant) => tenant?.tenantId === selectedTenantId
  );

  if (!selectedTenant) {
    console.warn('Tenant not found:', selectedTenantId);
    return;
  }

  tenantStore.setSelectedTenant(selectedTenant);

  if (route.name !== 'Home') {
    router.push({ name: 'Home' });
  }

  connectorConfigStore.$reset();
  taxonomyStore.$reset();
  configurationStore.$reset();
  usersStore.$reset();

  if (tenantStore.getSelectedTenantId) {
    await configurationStore.fetchTenantFeatureConfiguration(
      tenantStore.getSelectedTenantId,
      'feature-subscriptions'
    );
  }
};

onMounted(async () => {
  await tenantStore.fetchTenants();
  typeItems.value = tenantStore.getTenantSelectionList;
});
</script>
