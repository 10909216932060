<template>
  <div :class="$style['layout-grid']">
    <Header :class="$style['header']" />
    <SidebarMenu :class="$style['sidebar']" />
    <div :class="$style['content']">
      <RouterView />
    </div>
    <ToastMessage />

    <div :class="$style['version']">
      {{ version }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { RouterView } from 'vue-router';
import SidebarMenu from '@/components/navigation/SidebarMenu.vue';
import Header from '@/components/shared/Header.vue';
import ToastMessage from '@/components/shared/Toast.vue';
import useAuthStore from '@/store/auth';
import setupCoralogix from './vendor/setupCoralogix';

const version = import.meta.env.VITE_VERSION;
const isAuthenticated = computed(() => useAuthStore().isAuthenticated);

onMounted(async () => {
  if (isAuthenticated.value) {
    setupCoralogix();
  }
});
</script>

<style module lang="scss">

html, body {
  height: 100%;
  margin: 0;
}

.layout-grid {
  display: grid;
  grid-template-areas:
    "header header"
    "sidebar content";
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  height: 100vh; 
}

.header {
  grid-area: header;
  display: flex;
  align-items: center;
}

.sidebar {
  color: white;
  background-color: #2c4059 !important;
}

.content {
  grid-area: content;
  padding: 1rem;
  overflow-y: auto; 
}

.version {
  position: fixed;
  right: 0.5rem;
  bottom: 0.125rem;
  font-size: 9px;
  color: #808080;
}

a {
  &:hover {
    cursor: pointer;
  }
}
</style>
